import axios from 'axios';
import axiosInstance from '../../../axiosInterceptor';

const CATEGORY_API_URL = 'https://trjgvoq343.execute-api.ap-southeast-2.amazonaws.com/power_app_list_category_v1/';
const ADD_CATEGORY_API_URL = 'https://qc46o6ka6k.execute-api.ap-southeast-2.amazonaws.com/power_app_add_category_v1/';
const TABLE_DATA_API_URL = 'https://62soavtm37.execute-api.ap-southeast-2.amazonaws.com/power_app_get_audit_rule_rows/';
const ADD_AUDIT_RULE_API_URL = 'https://q2qu75shl2.execute-api.ap-southeast-2.amazonaws.com/power_app_add_audit_rule_v1/';
const SEARCH_AUDIT_RULE_API_URL = 'https://25pbjbve6f.execute-api.ap-southeast-2.amazonaws.com/power_app_get_audit_rule_by_ref_no_v1/';
const UPDATE_AUDIT_RULE_API_URL = 'https://5byg4cwt9h.execute-api.ap-southeast-2.amazonaws.com/power_app_update_audit_rule_v1/';

export const fetchCategories = async () => {
  try {
    const response = await axiosInstance.get(CATEGORY_API_URL);
    return response.data.category;
  } catch (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
};

export const fetchTableData = async (pageNumber) => {
  try {
    const response = await axiosInstance.get(TABLE_DATA_API_URL, {
      params: { pageNumber },
    });
    return response.data.audit_rules || [];
  } catch (error) {
    console.error('Error fetching table data:', error);
    throw error;
  }
};

export const addAuditRule = async (payload) => {
  try {
    const response = await axiosInstance.post(ADD_AUDIT_RULE_API_URL, { body: payload });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      alert(`Error: ${error.response.data.message}`);
    }
    throw new Error(error.response.data.message);
  }
};
export const updateAuditRule = async (payload) => {
  try {
    const response = await axiosInstance.post(UPDATE_AUDIT_RULE_API_URL, payload);
    return response.data;
  } catch (error) {
    console.error('Error updating audit rule:', error);
    throw new Error(error.response.data.message); 
  }
};
export const addCategory = async (payload) => {
  try {
    const response =await axiosInstance.post(ADD_CATEGORY_API_URL, payload);
    return response; 
  } catch (error) {
    console.error('Error adding category:', error);
    if (error.response) {
      return error.response; 
    } else {
      throw error; 
    }
  }
};
export const searchAuditRule = async (refNo) => {
  try {
    const response = await axiosInstance.get(SEARCH_AUDIT_RULE_API_URL, {
      params: { ref_no: refNo },
    });
    return response.data.audit_rows || [];  // Adjusted to return multiple rows
  } catch (error) {
    console.error('Error searching audit rule:', error);
    throw error;
  }
};