import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.css";
import chatLogo from "../../assets/chaticon.png";
import axios from "axios";
import { useViewport } from "../../utils/util";
import PencilSquare from "../../assets/svg/PencilSquare";
import { PencilImg } from "../../../web-workflow/assests/svgImages/Images";
import TrashFill from "../../assets/svg/TrashFill";
import Archive from "../../assets/svg/Archive";
import { Spinner } from "../../../web-workflow/common/Helper";
import InfiniteScroll from "../../../codercompanion/components/infinite-scroll";
import axiosInstance from "../../../../axiosInterceptor";

function Sidebar({
  conversations,
  handleClick,
  handleNewChatBtnClick,
  isOpen,
  setIsOpen,
  getConversations,
  fetchArchivedConversations,
  setMessages,
  isLoading,
  getConversationsFn,
  hasMoreConversationsVal,
  currentPageNum,
}) {
  console.log('PROPS1::::',isLoading, hasMoreConversationsVal,currentPageNum,conversations)
  const [hoveredChatId, setHoveredChatId] = useState(null);
  const dropdownRefs = useRef({});
  const [editingConversationId, setEditingConversationId] = useState(null);
  const [newConversationName, setNewConversationName] = useState("");
  const width = useViewport();
  const isMobile = width < 730;

  const handleConversationClick = (conversation) => {
    if (editingConversationId !== conversation.conversation_id) {
      console.log("CLICKED_CONVERSATION:::", conversation);
      handleClick(conversation);
      if (isMobile) {
        document.getElementsByClassName("sidebar-wrapper")[0].style.display =
          "none";
        setIsOpen && setIsOpen(!isOpen);
      }
    }
  };

  const startEditing = (conversation) => {
    setEditingConversationId(conversation.conversation_id);
    setNewConversationName(conversation.conversation_name);
  };

  const saveUpdatedName = (conversation) => {
    const updateApiEndpoint =
      "https://s3oliph7l2.execute-api.ap-southeast-2.amazonaws.com/llm_conversation_update/";
    const renamePayload = {
      body: {
        conversation_id: conversation.conversation_id,
        conversation_name: newConversationName,
        session_token: sessionStorage.getItem("accessToken"),
      },
    };
    axiosInstance
      .put(updateApiEndpoint, renamePayload)
      .then((response) => {
        console.log("Rename Conversations successful:", response);
        getConversationsFn(1, false);
        setEditingConversationId(null);
      })
      .catch((error) => {
        console.error("Error in rename request:", error);
      });
  };
  const handleDeleteClick = (e, conversation) => {
    e.stopPropagation();
    const deleteApiEndPoint =
      "https://sdvabbbidl.execute-api.ap-southeast-2.amazonaws.com/llm_delete_conversation/";
    const accessToken = sessionStorage.getItem("accessToken");
    const deletePayload = {
      body: {
        conversation_id: conversation.conversation_id,
        session_token: accessToken,
      },
    };
    axiosInstance
      .post(deleteApiEndPoint, deletePayload)
      .then((response) => {
        console.log("Delete Conversations successful:", response);
        getConversationsFn(1, false);
        setMessages([]);
      })
      .catch((error) => {
        console.error("Error in delete request:", error);
      });
  };
  const handleArchiveClick = (e, conversation) => {
    e.stopPropagation();
    const archiveApiEndPoint =
      "https://jna4ov2iz5.execute-api.ap-southeast-2.amazonaws.com/llm_archive_unarchive/";
    const accessToken = sessionStorage.getItem("accessToken");
    const archivePayload = {
      body: {
        conversation_id: conversation.conversation_id,
        session_token: accessToken,
        archive: true,
      },
    };
    axiosInstance
      .put(archiveApiEndPoint, archivePayload)
      .then((response) => {
        console.log("Archived Conversations successful:", response);
        getConversationsFn(1, true);
        fetchArchivedConversations();
      })
      .catch((error) => {
        console.error("Error in Archived request:", error);
      });
  };
  useEffect(() => {
    function handleClickOutside(event) {
      const clickedInside = Object.values(dropdownRefs.current).some(
        (ref) => ref && ref.contains(event.target)
      );
      if (!clickedInside) {
        setHoveredChatId(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRefs]);

  return (
    <div className="sidebar-wrapper">
      <div className="new-chat" onClick={handleNewChatBtnClick}>
        <img className="chat-logo-img" src={chatLogo} alt="Chat Logo" />
        New Chat
        <PencilSquare />
      </div>
      <InfiniteScroll
        className="sidebar-scroll"
        hasData={hasMoreConversationsVal}
        loadMore={() => getConversationsFn(currentPageNum, true)}
        isLoading={isLoading}
      >
          <>
            {conversations?.map((conversation) => (
              <div
                className="chatHistory"
                key={conversation.conversation_id}
                ref={(el) =>
                  (dropdownRefs.current[conversation.conversation_id] = el)
                }
                // onMouseEnter={() => setHoveredChatId(conversation.conversation_id)}
                // onMouseLeave={() => setHoveredChatId(null)}
              >
                {editingConversationId === conversation.conversation_id ? (
                  <input
                    className={newConversationName ? "chat-conversations" : ""}
                    type="text"
                    value={newConversationName}
                    onChange={(e) => setNewConversationName(e.target.value)}
                    onBlur={() => saveUpdatedName(conversation)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        saveUpdatedName(conversation);
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                      onClick={() => handleConversationClick(conversation)}
                      className="conversation-name"
                    >
                      {conversation.conversation_name}
                    </span>
                    <span
                      className="three-dots"
                      onClick={() =>
                        setHoveredChatId(
                          conversation.conversation_id === hoveredChatId
                            ? null
                            : conversation.conversation_id
                        )
                      }
                    >
                      ...
                    </span>
                  </div>
                )}
                {hoveredChatId === conversation.conversation_id && (
                  <div className="chat-options">
                    <div
                      className="option-button"
                      onClick={() => startEditing(conversation)}
                    >
                      <PencilImg />
                      <span className="options-name">Rename</span>
                    </div>
                    <div
                      className="option-button delete-button"
                      onClick={(e) => handleDeleteClick(e, conversation)}
                    >
                      <TrashFill />
                      <span className="options-name">Delete</span>
                    </div>
                    <div
                      className="option-button"
                      onClick={(e) => handleArchiveClick(e, conversation)}
                    >
                      <Archive />
                      <span className="options-name">Archive</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </>
      </InfiniteScroll>
    </div>
  );
}

export default Sidebar;
