import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../authContext/AuthContext";
import { authenticate } from "../../authContext/CognitoAuth"; 
import { BiLoader } from "react-icons/bi";
import ForgotPassword from "./ForgetPassword";

function Login({setShowLogin}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showLoginPage, setShowLoginPage] = useState(true);
  const navigate = useNavigate();
  const { setIsAuthenticated, setUserEmail } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const authResult = await authenticate(username, password);
      if (authResult) {
        const accessToken = sessionStorage.getItem("accessToken");

        const payload = {
          body: {
            user_name: username,
            session_token: accessToken,
          },
        };

        const apiEndpoint =
          "https://7tsdux8oni.execute-api.ap-southeast-2.amazonaws.com/llm_login/";

        await axios
          .post(apiEndpoint, payload)
          .then((response) => {
            let parsedBody = JSON.parse(response.data.body);
            let userEmail = parsedBody.message.user_email;
            sessionStorage.setItem("useremail", userEmail);
            setUserEmail(userEmail)
            sessionStorage.setItem("mode", "DARK");
            if (response.status === 200) {
              if (response.data.statusCode === 404) {
                const bodyData = JSON.parse(response.data.body);
                console.log(bodyData.message);
                sessionStorage.setItem("isAuthenticated", "false");
                setIsAuthenticated(false);
                navigate("/login");
                setError("Please login using your username, not your email...")
              }
            }
            if (response.status === 200) {
              if (response.data.statusCode === 200) {
                // Update sessionStorage storage and state
                sessionStorage.setItem("isAuthenticated", "true");
                setIsAuthenticated(true);
                navigate("/");
              }
            }
          })
          .catch((error) => {
            throw new Error("Failed to send data: " + error.message);
          });
      } else {
        throw new Error("Authentication failed");
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return showLoginPage ? (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>Login</h2>
        <div className="input-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setError("");
            } }
            disabled={loading}
          />
        </div>
        <div className="input-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password} 
            onChange={(e) => {
              setPassword(e.target.value);
              setError("");  
            }}
            disabled={loading}
          />
        </div>
        <button
          className="Login-btn btn btn-warning"
          type="submit"
          disabled={loading}
        >
          {loading && !error ? <BiLoader /> : "Log In"}
        </button>
        {error && <p className="login-errorMessage">{error}</p>}
        <div>
          <p className="switchSignUp">
            Don't have an account? <span onClick={() => setShowLogin(false)}> Sign Up</span>
          </p>
          <p className="forgotPassword" onClick={() => setShowLoginPage(false)}>Forgot Password?</p>
        </div>
      </form>
    </div>
  ) : (
    <ForgotPassword setShowLoginPage={setShowLoginPage} />
  );
}

export default Login;
