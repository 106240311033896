import axios from "axios";
import axiosInstance from "../../../axiosInterceptor";

export const getS3Url = async (folderName, fileName) => {
  const url =
    "https://g8nnp2myde.execute-api.ap-southeast-2.amazonaws.com/upload_hcp_files_to_s3/";
  return await axiosInstance
    .get(`${url}?folder_name=${folderName}&file_name=${fileName}`)
    .then((res) => res.data.signed_url)
    .catch((err) => {
      throw new Error(err.message);
    });
};
export const getJobRunId = async (s3_uri) => {
  try {
    const response = await axiosInstance.get(
      "https://lu1di634ld.execute-api.ap-southeast-2.amazonaws.com/call_glue_job_work_flow_tool/",
      {
        params: { s3_uri },
      }
    );
    console.log("GLUE_MAIN:::", response);
    const jobRunId = response.data.job_run_id;
    return jobRunId;
  } catch (err) {
    throw new Error(err.message);
  }
};
export const checkGlueStatus = async (jobRunId) => {
  const url =
    "https://l7agaksgl4.execute-api.ap-southeast-2.amazonaws.com/workflow_check_job_status_s3_to_rds/?job_run_id=";
  try {
    const response = await axiosInstance.get(`${url}${jobRunId}`);
    return response.data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fileUpload = async (s3Url, formData) => {
  return await axios
    .post(s3Url, formData, {
      headers: "multipart/form-data",
    })
    .then((res) => res)
    .catch((err) => {
      throw new Error(err.message);
    });
};

export const getAuditFiles = async () => {
  const url =
    "https://gkrtpx6lr9.execute-api.ap-southeast-2.amazonaws.com/get_audit_files/";
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getPowerBIFiles = async () => {
  const url =
    "https://0arletqk9f.execute-api.ap-southeast-2.amazonaws.com/get_files_for_power_bi/";
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getFindingsData = async (body) => {
  const url =
    "https://q76zx9mci2.execute-api.ap-southeast-2.amazonaws.com/power_bi_findings_data/";
  try {
    const response = await axiosInstance.post(url, { body });

    const parsedData = JSON.parse(response.data.body);
    if (typeof parsedData.message === "string") {
      throw new Error(parsedData.message);
    }
    return parsedData;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getCodingImprovementsData = async (body) => {
  const url =
    "https://ofwvx27t37.execute-api.ap-southeast-2.amazonaws.com/coding_improvements_data_v1/";
  try {
    const response = await axiosInstance.post(url, { body });

    const parsedData = JSON.parse(response.data.body);
    if (typeof parsedData.message === "string") {
      throw new Error(parsedData.message);
    }
    return parsedData;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getFocusAreasData = async (body) => {
  const url =
    "https://7i912phq60.execute-api.ap-southeast-2.amazonaws.com/focus_area_data_v2/";
  try {
    const response = await axiosInstance.post(url, { body });

    const parsedData = JSON.parse(response.data.body);
    if (typeof parsedData.message === "string") {
      throw new Error(parsedData.message);
    }
    return parsedData.message;
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getRcmOverviewData = async (body) => {
  const url =
    "https://8y7ntxzwa8.execute-api.ap-southeast-2.amazonaws.com/rcm_overview_v3/";
  try {
    const response = await axiosInstance.post(url, { body });

    console.log("PARSEDDATA:::::", response)
    const parsedData = JSON.parse(response.data.body);
    if (typeof parsedData.message === "string") {
      throw new Error(parsedData.message);
    }
    return parsedData.message;
  } catch (error) {
    throw new Error(error.message);
  }
};
