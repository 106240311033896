// src/axiosInstance.js
import axios from 'axios';


const axiosInstance=axios.create({
  
});
// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the config to include your custom headers
    const token = sessionStorage.getItem('accessToken'); // or wherever you store your token
    if (token) {
      config.headers['authorizationToken'] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Response interceptor (optional)
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors globally
    if(error.response && (error.response.status===401 || error.response.status===403)){
      let errorMessage='';
      if(error.response.status===401){
        errorMessage='Please login again.'
      }else if(error.response.status===403){
        errorMessage='You do not have permission to access this resource'
      }
      window.dispatchEvent(new CustomEvent('tokenExpired', {detail:{errorMessage}}));
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
