import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "../authContext/ProtectedRoute"; 
import WebWorkFlowMain from "../projects/web-workflow";
import ChatAIMainPage from "../projects/chat-ai";
import CoderCompanion from "../projects/codercompanion";
import ViewDashboard from "../projects/codercompanion/pages/ViewDashboard";
import PowerApp from "../projects/Power-Apps";  
import LandingCards from "../pages/landingPage/LandingCards/LandingCards";
import FeedbackForm from "../projects/feedback/Feedback";
import UserFeedback from "../projects/feedback/UserFeedbacks";
// import PowerApp from "../projects/Power-Apps";  

const RoutesPage = ({isRowClicked, setIsRowClicked}) => {
   

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/discover-features" />}
      /> 
      <Route
        path="/workflow-companion"
        element={
          <ProtectedRoute>
            <WebWorkFlowMain isRowClicked={isRowClicked} setIsRowClicked={setIsRowClicked}/>
          </ProtectedRoute>
        }
      />
      <Route
        path="/chat-ai"
        element={
          <ProtectedRoute>
            <ChatAIMainPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/discovery-companion"
        element={
          <ProtectedRoute>
            <CoderCompanion />
          </ProtectedRoute>
        }
      />
      <Route
        path="viewdashboard"
        element={
          <ProtectedRoute> 
            <ViewDashboard/>
          </ProtectedRoute>
        }
      />
      <Route
        path="powerapp"
        element={
          <ProtectedRoute> 
            <PowerApp/>
          </ProtectedRoute>
        }
      /> 
      <Route
        path="feedback"
        element={
          <ProtectedRoute> 
           <FeedbackForm/>
          </ProtectedRoute>
        }
      /> 
       <Route
        path="user-feedbacks"
        element={
          <ProtectedRoute> 
            <UserFeedback/>
          </ProtectedRoute>
        }
      /> 
       <Route
        path="/discover-features"
        element={
          <ProtectedRoute> 
          <LandingCards/>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default RoutesPage;
