import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AuditDetails.css";
import leftarrow from "../../assests/left arrow.png";
import rightarrow from "../../assests/right arrow.png";
import {
  additionalDiagnosesFields,
  leftSideSections,
  procedureCodesFields,
  rightSideSections,
} from "./Constants";
import { PencilImg } from "../../assests/svgImages/Images";
import { extractLoadId, extractUserName, formatDate } from "../../utils/util";
import filtertick from "../../assests/filtertick.png";
import { auditStatusUpdate } from "../../api/Service";
import { Spinner } from "../../common/Helper";
import tick from "../../assests/tick-check.png";
import bell from "../../assests/newbell.png";
import folderimage from "../../assests/file_audit.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Version2Message } from "../../../chat-ai/components/chatpage/Message";
import axiosInstance from "../../../../axiosInterceptor";
const viewAllBtns = ["Procedure codes", "Additional diagnosis"];

const Section = ({ name, value, procedureCodes, additionalDiagnoses }) => {
  const [viewAllClick, setViewAllClick] = useState(false);
  const onViewAllClick = () => {
    setViewAllClick(true);
  };
  return (
    <div className="audit-values-row">
      <div className="row-name">{name}:</div>
      <div className="row-value">
        {name === "Episode failed multiple rules" ? (
          <div> {value ? "Yes" : "No"} </div>
        ) : (
          <>
            <>{value}</>
            &nbsp;
            {viewAllBtns.includes(name) && (
              <button className="view-all-codes-btn" onClick={onViewAllClick}>
                View All
              </button>
            )}
          </>
        )}
      </div>
      {
        <div
          className={`modal fade ${viewAllClick ? "show" : ""}`}
          style={{ display: viewAllClick ? "block" : "none" }}
          tabIndex="-1"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{name}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setViewAllClick(false)}
                ></button>
              </div>
              <div className="modal-body">
                {name === "Procedure codes" && (
                  <div>
                    {procedureCodes.map(({ key, value }, index) => (
                      <div key={index}>
                        {key}:{value === "nan" ? "no value" : value}
                      </div>
                    ))}
                  </div>
                )}
                {name === "Additional diagnosis" && (
                  <div>
                    {additionalDiagnoses.map(({ key, value }, index) => (
                      <div key={index}>
                        {key}:{value === "nan" ? "no value" : value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

const NoRecordSelected = () => {
  return <div className="text-center ">No Record Selected</div>;
};
function AuditDetails({
  auditDetails,
  handlePreviousClick,
  handleNextClick,
  selectedIndex,
  length,
  setAuditDetails,
  getUpdatedAuditDetails,
  getAuditRowsData,
  setIsRowClicked,
  priorityVal5,
}) {
  // console.log(auditDetails, auditDetails.drg_code, "auditDetails::::");
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => setShowModal(!showModal);
  const [inputValue, setInputValue] = useState(
    `The DRG is ${auditDetails.drg_code}. Generate a multiple-choice format CDQ to send the clinician to support ${auditDetails.audit_rule_short_description} that could result in higher complexity`
  );
  const [textValue, setTextValue] = useState(auditDetails.audit_comment || "");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [iscdqloading, setIscdqloading] = useState(false);
  const [isCommentsLoading, setIsCommentsLoading] = useState(false);
  const [isEditloading, setIsEditloading] = useState(true);  
  const procedureCodes = Array.from({ length: 50 }, (_, i) => {
    const key = `procedure_codes${i + 1}`;
    return { key, value: auditDetails[key] };
  });
  const additionalDiagnoses = Array.from({ length: 49 }, (_, i) => {
    const key = `additional_diagnoses${i + 1}`;
    return { key, value: auditDetails[key] };
  });
  const handleEditChange = (key, value) => {
    setAuditDetails((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
  };

  const handleEditSave = async () => {
    setIsEditloading(true)
    try {
      auditStatusUpdate({
        ...auditDetails,
        predicted_drg_revenue: auditDetails.predicted_drg_revenue,
        is_flagged: false,
      })
        .then((res) => {
          const { message } = res;
          // console.log(message, "message_priority:::");
          getUpdatedAuditDetails(
            message.id,
            auditDetails.audit_status,
            message.priority_value
          );
          toggleModal(); // Optionally close modal on successful update
        })
        .catch((err) => console.error("Status Update Error::", err.message));
    } catch (error) {
      console.error("Failed to update audit details:", error);
    }finally {
      setIsEditloading(false)
    }
  };
  const handleStatusChange = async (e) => {
    // If the selected value is "Update status", set audit_status to "Failed"
    const selectedValue = e.target.value;
    let auditStatus = selectedValue === "" ? "Failed" : selectedValue;
    auditStatusUpdate({
      ...auditDetails,
      audit_status: auditStatus,
      predicted_drg_revenue: auditDetails.predicted_drg_revenue,
      is_flagged: false,
    })
      .then((res) => {
        const { message } = res;
        // console.log(message, "status_message::");
        getUpdatedAuditDetails(message.id, auditStatus, message.priority_value);
      })
      .catch((err) => console.error("Status Update Error::", err.message));
  };
  const handleDontflagClick = () => {
    setLoading(true);
    try {
      auditStatusUpdate({
        ...auditDetails,
        predicted_drg_revenue: auditDetails.predicted_drg_revenue,
        is_flagged: true,
      })
        .then(() => {
          setAuditDetails({});
          getAuditRowsData({});
        })
        .catch((err) => console.error("Status Update Error::", err.message))
        .finally(() => {
          setLoading(false);
          setIsRowClicked(false);
        });
    } catch (error) {
      console.error("Failed to update audit details:", error);
      setLoading(false);
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleGenerateCDQ = async () => {
    setIscdqloading(true);
    try {
      const response = await axiosInstance.post(
        "https://l2mc3dvaa4.execute-api.us-west-2.amazonaws.com/lambdaAWSQV2/",
        {
          prompt: inputValue,
        }
      );
      const responseData = JSON.parse(response.data.body);
      const messageChunks = responseData.meesage;
      // console.log(messageChunks, "clinicaldata:::");
      setModalContent(messageChunks);
    } catch (error) {
      console.error("Error making POST request", error);
      setModalContent("An error occurred while making the request.");
    } finally {
      setIscdqloading(false);
      setModalVisible(true);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();

    if (textValue.trim() === "") {
      alert("Please enter some text.");
      return;
    }
    setIsCommentsLoading(true);
    try {
      const response = await axiosInstance.post(
        "https://k4199a4e1l.execute-api.ap-southeast-2.amazonaws.com/add_audit_comment_v2/",
        {
          audit_comment: textValue,
          id: auditDetails.id,
          priority_value: auditDetails.priority_value,
        }
      ).then(res => {
        const body = JSON.parse(res.data.body);
        const { id, priority_value } = body.message;
        // console.log("ID:::::", id,priority_value); 
        getUpdatedAuditDetails(id,auditDetails.audit_status,priority_value)
  
      // console.log("Comment submitted  successfully!"); 
      alert("Comments submitted successfully!");
      setTextValue("");  
    });
  
      setTextValue("");
    } catch (error) {
      console.error("Error submitting data:", error);
    } finally {
      setIsCommentsLoading(false);
    }
  };
  return (
    <div className="audit-details">
      <div className="webworkflow-crumb-wrapper">
        <span
          onClick={() => setIsRowClicked(false)}
          className="workflow-bread-crumb-first"
        >
          Workflow Companion
        </span>{" "}
        {">"}{" "}
        <span className="workflow-bread-crumb-second">
        {auditDetails.audit_rule_short_description}
        </span>
      </div>
      <div>
        <h1 className="audit-deatils-header-text">
        {auditDetails.audit_rule_short_description}
        </h1>
      </div>
      <div className="d-flex justify-content-start align-items-center gap-2 mb-3">
        <div className="status-container">
          <label className="dropdown-label" htmlFor="dropdown">
            Status:
          </label>{" "}
          <select
            id="dropdown"
            defaultValue={auditDetails.audit_status}
            onChange={handleStatusChange}
            className="status-dropdown"
            disabled={priorityVal5}
          >
            <option value="">Update status</option>
            <option value="Flagged for Review">Flagged for Review</option>
            <option value="Reviewed,no change">Reviewed, no change</option>
            <option value="Reviewed,query required">
              Reviewed, query required
            </option>
            <option value="Reviewed,query sent">Reviewed, query sent</option>
            <option value="Reviewed,recoded">Reviewed, recoded</option>
            <option value="Reviewed,rebilled">Reviewed, rebilled</option>
            <option value="Reviewed,reclaimed">Reviewed, reclaimed</option>
          </select>
        </div>
        <button
          className="donot-flag"
          onClick={handleDontflagClick}
          disabled={priorityVal5}
        >
          {loading ? (
            <div className="d-flex">
              Do not flag this episode anymore
              <Spinner className="donot-flag-loader" />
            </div>
          ) : (
            <div>
              Do not flag this episode anymore <img src={tick} alt="tick" />
            </div>
          )}
        </button>
      </div>
      {auditDetails.name ? (
        <div className="audit-main">
          <div className="audit-details-header">
            {/* <FilesImg /> */}
            <div  className="folder-image-wrapper">
            <img className="folder-image" src={folderimage} alt="folderimg" />
            </div>
            <p className="user-name">{extractUserName(auditDetails.name).replace(/[\d-]+.*$/,'')} </p>
            <p className="addmission-date">
              Admission Date: {auditDetails.admission_date}
            </p>
            <p className="seperation-date">
              Separation Date: {auditDetails.seperation_date}
            </p>
            <p className="Priority-value">
              Priority value:{" "}
              <div className={`audit-count p${auditDetails.priority_value}`}>
                P{auditDetails.priority_value}
              </div>{" "}
            </p>
            <div className="insurer-identifier-parent">
              Insurer identifier:{" "}
              <div className="insurer-identifier">
                {auditDetails.insurer_identifier}
              </div>
            </div>
          </div>

          <div className="status-container-value-spacing">
            <h5>
              {auditDetails.audit_rule_category}
              <button
                className="editAuditDetails-btn"
                onClick={toggleModal} 
                style={{ border: "none", background: "white", color: "black" }}
              >
                <PencilImg />
              </button>
              {/* Audit details edit Modal */}
              <div
                className={`modal fade ${showModal ? "show" : ""}`}
                style={{ display: showModal ? "block" : "none" }}
                tabIndex="-1"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Edit Audit Details</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={toggleModal}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="auditrule-discription-modal">
                        <input
                          value={auditDetails.audit_rule_short_description}
                          disabled
                          onChange={(e) =>
                            handleEditChange(
                              "audit_rule_short_description",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="auditrule-discription-modal">
                        <input
                          value={auditDetails.audit_rule_long_description}
                          disabled
                          onChange={(e) =>
                            handleEditChange(
                              "audit_rule_long_description",
                              e.target.value
                            )
                          }
                        />
                      </div>

                      <div className="audit-description">
                        <div className="audit-description-left">
                          {leftSideSections.map((section, i) => (
                            <React.Fragment key={i}>
                              <div>{section.title}</div>
                              <input
                                className="audit-description-left-input"
                                value={auditDetails[section.key]}
                                onChange={(e) =>
                                  handleEditChange(section.key, e.target.value)
                                }
                                disabled={
                                  section.key === "episode_identifier" ||
                                  section.key === "medical_record_number"
                                }
                              />
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="audit-description-right">
                          {rightSideSections.map((section) => (
                            <>
                              <div>{section.title}</div>
                              <input
                                className="audit-description-right-input"
                                value={auditDetails[section.key]}
                                onChange={(e) =>
                                  handleEditChange(section.key, e.target.value)
                                }
                              />
                            </>
                          ))}
                        </div>
                      </div>
                      <div className="procedure-codes-wrapper">
                        {procedureCodesFields.map((section, i) => (
                          <div className="procedure-code-field" key={i}>
                            <div>{section.title}</div>
                            <input
                              className="procedure-code-field-input"
                              value={auditDetails[section.key]}
                              onChange={(e) =>
                                handleEditChange(section.key, e.target.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                      <div className="additional-diagnoses-wrapper">
                        {additionalDiagnosesFields.map((section, i) => (
                          <div className="additional-diagnoses-field" key={i}>
                            <div>{section.title}</div>
                            <input
                              className="additional-diagnoses-field-input"
                              value={auditDetails[section.key]}
                              onChange={(e) =>
                                handleEditChange(section.key, e.target.value)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={toggleModal}
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleEditSave}
                      >
                        <span>
                          <img src={filtertick} alt="Filter Tick" />
                        </span>{" "}
                        {isEditloading ? "Save":"Saving"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </h5>
            <div className="charge-discription-wrapper">
              <p className="charge-discription">
                {auditDetails.audit_rule_long_description}
              </p>
              {/* <PencilImg /> */}
            </div>
            <div className="missing-link">
              <img src={bell} alt="bell" />
              {auditDetails.audit_rule_category}
            </div>
            <div className="audit-description">
              <div className="audit-description-left">
                {leftSideSections.map((section) => (
                  <Section
                    key={section.key}
                    name={section.title}
                    value={auditDetails[section.key]}
                    procedureCodes={procedureCodes}
                    additionalDiagnoses={additionalDiagnoses}
                  />
                ))}
              </div>
              <div className="audit-description-right">
                {rightSideSections.map((section) => (
                  <Section
                    key={section.key}
                    name={section.title}
                    value={auditDetails[section.key]}
                    procedureCodes={procedureCodes}
                    additionalDiagnoses={additionalDiagnoses}
                  />
                ))}
              </div>
            </div>
            <div className="d-flex gap-3">
            <div className="type-info-wrapper">
                <label>Clinician Query:</label>
                <div className="d-flex flex-column">
                  <input
                    className="type-info"
                    type="text"
                    placeholder="Type in info about Clinician Query"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <button
                    className="generate-cdq-btn"
                    onClick={handleGenerateCDQ}
                    disabled={iscdqloading || !inputValue.length}
                  >
                    {iscdqloading ? "Generating..." : "Generate CDQ"}
                  </button>
                </div>
              </div>
              <div className="comments-text-wrapper">
                <label>Audit Comments:</label>
                <textarea
                  value={textValue}
                  onChange={(e) => setTextValue(e.target.value)}
                  placeholder={"Type your comments here..."}
                  style={{
                    padding: "8px",
                    width: "100%",
                    height: "100px",
                    boxSizing: "border-box",
                  }}
                />
                <button
                  className="audit-comment-btn"
                  onClick={handleCommentSubmit}
                >
                  {isCommentsLoading ? "Adding Comment..." : "Add Comment"}
                </button>
              </div>

              <div
                className={`modal fade ${modalVisible ? "show" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{ display: modalVisible ? "block" : "none" }}
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title cdq-header-text">
                        CDQ Response
                      </h5>
                    </div>
                    <div className="modal-body">
                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <div style={{ whiteSpace: "pre-line" }}>
                          {modalContent.error ? (
                            <p>{modalContent.error}</p>
                          ) : (
                            <Version2Message
                              message={{ answer: modalContent }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setModalVisible(false)}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="toggle-next-prev">
              <div>
                Row {selectedIndex + 1} of <strong>{length}</strong>
              </div>
              <div className="previous-next-wrapper">
                {selectedIndex + 1 !== 1 && (
                  <button
                    className="previous-btn"
                    disabled={selectedIndex + 1 === 1}
                    onClick={handlePreviousClick}
                  >
                    <img src={leftarrow} alt="leftarrow" /> Previous
                  </button>
                )}
                <button
                  className="next-btn"
                  disabled={selectedIndex + 1 === length}
                  onClick={handleNextClick}
                >
                  Next <img src={rightarrow} alt="rightarrow" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoRecordSelected />
      )}
    </div>
  );
}

export default AuditDetails;
