import { Alert, Button, Col, Row } from "react-bootstrap";
import Addicon from "../assets/png/light.png";
import PlaySvg from "../assets/svg/playcircled.svg";
import FileSvg from "../assets/svg/file.svg";
import RightArrow from "../assets/svg/arrowright.svg";
import { forwardRef, useEffect, useRef, useState } from "react";
// import { Storage } from "@aws-amplify/storage";
import DatePicker from "react-datepicker";
// import moment from "moment/moment";
import dayjs from "dayjs";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSnackbar } from "notistack";
import backArrow from "../assets/svg/circle_arrow_back.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  checkGlueStatus,
  fileUpload,
  getJobRunId,
  getS3Url,
} from "../api/Service";
import axios from "axios";
import { useAuth } from "../../../authContext/AuthContext";
import axiosInstance from "../../../axiosInterceptor";

const TabSection = ({ type, setFiles, fileDetails, switchTab, setType }) => {
  const navigate = useNavigate();
  const { userEmail } = useAuth();
  const [s3Uri, setS3Uri] = useState("");
  const [completed, setCompleted] = useState("0");
  const [loadId, setLoadId] = useState("");
  const [progress, setProgress] = useState(0);
  const [circleColor, setCircleColor] = useState("blue");
  const [jobId1, setJobId1] = useState("");
  const [callCheckStatusFunction, setCallCheckStatusFunction] = useState(false);
  const [filess, setFiless] = useState([]);
  const [isStartSelected, setIsStartSelected] = useState(false);
  const [showBar, setShowBar] = useState(true);
  const [isEndDateSelected, setIsEndDateSelected] = useState(false);
  const [stepFunResponse, setStepFunResponse] = useState({
    response: {},
    isCalled: false,
  });
  const [isRunDisabled, setIsRunDisabled] = useState(false);
  // const [historyFunResponse, setHistoryFunResponse] = useState([]);
  // const [responseProgressType, setResponseProgressType] = useState("");
  const [alertStatus, setAlert] = useState(false);
  // const [ProgressStatus, setStatus] = useState("start");
  const fileReference = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [auditDownloadFileStatus, setAuditDownloadFileStatus] = useState(
    "Download Audit File"
  );

  let intervalId = null;
  console.log("file:::", fileDetails);

  useEffect(() => {
    if (callCheckStatusFunction) {
      intervalId = setInterval(async () => {
        checkGlueJobStatus(jobId1, intervalId);
      }, 10000);
    }

    // Cleanup function to clear the interval when component unmounts or when callCheckStatusFunction becomes false
    return () => {
      clearInterval(intervalId);
    };
  }, [callCheckStatusFunction, jobId1]);

  const checkGlueJobStatus = async (jobId, intervalId) => {
    try {
      const response = await checkGlueStatus(jobId);
      console.log("STATUS:::::", response);
      const gg = response.JobRun;
      const jobRunState = response.JobRun.JobRunState;
      if (jobRunState === "RUNNING") {
        const rawProgress = (gg.ExecutionTime / gg.Timeout) * 100;
        const roundedProgress = Math.floor(rawProgress);

        setProgress(roundedProgress);
      } else if (jobRunState === "FAILED") {
        console.log("Failed");
        setShowBar(false);
        clearInterval(intervalId);
        setProgress(0);
        setCircleColor("red");
        setCallCheckStatusFunction(false);
        setCompleted("-1");
        enqueueSnackbar("Error processing the file", {
          variant: "Error",
        });
      } else if (jobRunState === "SUCCEEDED") {
        console.log("Success");
        setShowBar(false);
        clearInterval(intervalId);
        setProgress(100);
        setCircleColor("green");
        setCallCheckStatusFunction(false);
        setCompleted("1");
        enqueueSnackbar("Successfully uploaded the file", {
          variant: "success",
        });
      } else {
        console.log("Stopping interval");
        clearInterval(intervalId);
      }
    } catch (error) {
      console.error("Error checking Glue job status:", error.message);
    }
  };
  const callGlueJob = async (url) => {
    try {
      const result = await getJobRunId(url);
      setCallCheckStatusFunction(true);
      console.log("GLUE:::", result);
      setJobId1(result);
    } catch (err) {
      console.error("Call_GLUE_JOB_ERROR::", err.message);
    }
  };  // CSV file upload
  const handleUploads = async (file, type, folder) => {
    console.log(loadId, "");
    console.log(
      `${folder}/${file.name}`,
      `input/${file.name}` // Header.csv
    );
    console.log("UPLOADS:::", { file, type, folder, loadId });

    try {
      const result = await getS3Url(folder, file.name);
      const { url, fields } = result;
      const formData = new FormData();
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });
      formData.append("file", file);

      const uploadData = await fileUpload(url, formData);
      console.log("UPLOADED:::", uploadData, url);
      //to show Run button and should be enabled
      setIsRunDisabled(false);
      enqueueSnackbar(
        type === "hcp" || type === "csv"
          ? "Csv files uploaded successfully"
          : "Date range Uploaded successfully",
        { variant: "success" }
      );

      if (type === "hcp") {
        switchTab("selectDates");
      } else {
        switchTab("uploadTile");
      }
    } catch (error) {
      console.log(error, "err");
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  // Uploaded HCP files
  const newUpload = async (files, type, loadnumber) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = file.name;

      try {
        const result = await getS3Url(loadnumber, file.name);
        console.log("S#RWESSSS:::NEWWW", result);
        const { url, fields } = result;
        const formData = new FormData();
        Object.keys(fields).forEach((key) => {
          formData.append(key, fields[key]);
        });
        formData.append("file", file);
        const uploadData = await fileUpload(url, formData);
        console.log("UPLOADED:::NEWWW", uploadData, url);

        console.log(`File ${fileName} uploaded successfully!`);

        sessionStorage.setItem("filename", loadnumber);
      } catch (error) {
        console.error(`Error uploading file ${fileName}:`, error);
      }
    }
    alert("File Uploaded successfully");
    callGlueJob(`s3://hcp-file-uploads/public/${loadnumber}`);
    enqueueSnackbar("Uploaded other files successfully", {
      variant: "success",
    });
  };

  const handleCsvFormat = () => {
    console.log("1::::", fileDetails);
    const { uploadFile, ...dateRange } = fileDetails;
    console.log("dates::::", dateRange)// 
    const start = dayjs(dateRange.startDate).format("D-MMM-YY");
    const end = dayjs(dateRange.endDate).format("D-MMM-YY");
    console.log("date::", {start, end})
    const orgName = "iMedX";
    const sliceduser = userEmail.split("@")[0];
    const currentTimestamp = new Date().getTime();
    const loadIds = `${sliceduser}-${orgName}-${currentTimestamp}`;
    setLoadId(loadIds);
    //const csvData = `start_date,end_date,organization_name,user_name,user_id,email_address,load_id\r\n${start},${end},${orgName},${userName},${id},${userEmail},${loadIds}`;
    const csvData = `start_date,end_date,user_name,email_address,load_id\r\n${start},${end},${sliceduser},${userEmail},${loadIds}`;

    const file = new File(
      [csvData],
      process.env.REACT_APP_EXPORT_CSV_FILE_NAME,
      { type: "text/csv" }
    );
    handleUploads(file, "csv", loadIds);
    newUpload(filess, "txt", loadIds);
    console.log("UPLOAD_DATA:::", {
      start,
      end,
      filess,
      userEmail,
      loadIds,
      csvData,
      env: process.env.REACT_APP_EXPORT_CSV_FILE_NAME,
      file,
    });
    enqueueSnackbar(
      type === "hcp"
        ? "File uploaded successfully"
        : "Date range Uploaded successfully",
      { variant: "success" }
    );
  };

  const handleFileUpload = () => {
    fileReference.current.click();
  };

  const handledownloadFileClick = () => {
    setAuditDownloadFileStatus("Downloading...");
    const filename = sessionStorage.getItem("filename");
    let trimmedfilename = filename.replace(/\s+/g, "");
    const csvFilename = trimmedfilename + ".csv";
    axiosInstance
      .get(
        `https://unk846doz0.execute-api.ap-southeast-2.amazonaws.com/download_audit_files_work_flow_tool/?file_name=${csvFilename}`
      )
      .then((response) => {
        const { url } = response.data;
        const link = document.createElement("a");
        link.href = url;
        console.log(url, response, "checkurl:::");
        link.setAttribute("download", csvFilename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setAuditDownloadFileStatus("Download Again");
      });
  };
  const resetAllStates = () => {
    setS3Uri("");
    setShowBar(true);
    setCompleted("0");
    setLoadId("");
    setProgress(0);
    setCircleColor("blue");
    setJobId1("");
    setCallCheckStatusFunction(false);
    setFiless([]);
    setIsStartSelected(false);
    setIsEndDateSelected(false);
    setType("upload");
    setAuditDownloadFileStatus("Download Audit file");
  };

  const handleBack = () => {
    resetAllStates();
  };
  const handleBackToLink = () => {
    navigate("/viewdashboard");
  };

  const handleProgress = async () => {
    console.log("RUNBTN::::");
    setIsRunDisabled(true);
    handleCsvFormat();

    if (fileDetails.startDate === null || fileDetails.endDate === null) {
      setAlert(true);
    } else {
      setTimeout(() => {
        console.log("progress on the way");
      }, 2000);
    }
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    setFiless(selectedFiles);
    switchTab("selectDates");
  };

  const StartDates = forwardRef(({ value, onClick }, ref) => (
    <span className="periodText" onClick={onClick} ref={ref} role="button">
      {value === ""
        ? "StartDate - EndDate"
        : value.includes("-") && fileDetails.endDate === null
        ? `${value} EndDate`
        : value}
    </span>
  ));


  const UploadSection = () => {
    return (
      <section className="w-100 mt-5 pt-5 pb-3 d-flex flex-column align-items-center">
        <p>Upload The Hcp Files.(Hold Control For Multiple)</p>

        {/* <button onClick={glucose}>run glue job</button> */}

        <input
          type="file"
          className="d-none"
          ref={fileReference}
          onInput={handleFileChange}
          multiple
          accept=".hcp"
        />
        <Button
          className="w-100 py-3 primaryButton font-weight-bold"
          onClick={handleFileUpload}
        >
          <img className="addicon-files" src={Addicon} alt="add icon" /> Browse
          for File(s)
        </Button>
      </section>
    );
  };
  const SelectDates = () => {
    return (
      <section className="w-100 mt-4 d-flex flex-column align-items-center ">
        <div className=" border rounded w-100 p-3 mb-4 font-weight-bold">
          <Row className="px-2 pb-4">
            {alertStatus && (
              <Alert variant="danger">Please Select the Period</Alert>
            )}

            {filess.length > 0 &&
              filess.map((fileName, index) => {
                // console.log(fileName);
                return (
                  <Col
                    md={6}
                    className="d-flex align-items-center p-0"
                    key={index}
                  >
                    <img src={FileSvg} alt="file icon" />
                    <p className="m-0 ps-2">{fileName.name}</p>
                  </Col>
                );
              })}
          </Row>
          <div className="selectPeriod px-4 py-3">
            <img src={RightArrow} alt="rightArrow" />
            <span className="m-0 ps-3">Select Period:</span>        
            <div>
            <label className="mx-2">Start Date:</label>
              <DatePicker
                maxDate={fileDetails.endDate}
                selected={fileDetails.startDate}
                onChange={(date) => {
                  const {startDate, endDate} = fileDetails
                  if ((endDate && date < endDate) || !startDate || !endDate) {
                    setFiles((prev) => ({
                      ...prev,
                      startDate: date,
                    }));
                  }
                }}
                onChangeRaw={(e) => {
                  const input = e.target.value;
                  if (/\d{5,}/.test(input)) {
                    e.preventDefault();  
                  }
                }}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <div>
              <label className="mx-2 py-3">End Date:</label>
              <DatePicker
                selected={fileDetails.endDate}
                minDate={fileDetails.startDate}
                onChange={(date) => {
                  const {startDate, endDate} = fileDetails
                  if ((startDate && date > startDate) || !endDate || !startDate) {
                    setFiles((prev) => ({
                      ...prev,
                      endDate: date,
                    }));
                  }
                }}
                onChangeRaw={(e) => {
                  const input = e.target.value;
                  if (/\d{5,}/.test(input)) {
                    e.preventDefault(); 
                  }
                }}
                dateFormat="dd-MM-yyyy"
              />
              {/* <button onClick={handleCsvFormat}>save csv</button> */}
            </div>
            </div>
          </div>
        <div className="d-flex align-items-center w-100 gap-4">
          <Button
            className="w-100 py-3 primaryButton font-weight-bold mb-4"
            onClick={handleBack}
          >
            <img
              src={backArrow}
              className="me-2"
              alt="play"
              width="28rem"
              height="28rem"
            />{" "}
            Back
          </Button>
          <Button
            className="w-100 py-3 primaryButton font-weight-bold mb-4"
            onClick={handleProgress}
            disabled={!(fileDetails.startDate && fileDetails.endDate) || isRunDisabled}
          >
            <img src={PlaySvg} className="me-2" alt="play" />
            {isRunDisabled ? "Uploading" : " Run"}
          </Button>
        </div>
      </section>
    );
  };
  const renderSection = (type) => {
    switch (type) {
      case "upload":
        return <UploadSection />;
      case "selectDates":
        return <SelectDates />;
      case "uploadTile":
        return (
          <>
            <div className="d-flex">
              <div style={{ width: 150, height: 150 }}>
                <CircularProgressbar
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    rotation: 0.25,

                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",

                    // Text size
                    textSize: "16px",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    // pathColor: `rgba(0, 255, 0, ${progress / 100})` ?,
                    pathColor:
                      completed === "1"
                        ? `rgba(0, 255, 0, ${progress})`
                        : completed === "-1"
                        ? `rgba(255, 0, 0, ${progress})`
                        : `rgba(0, 0, 255, ${progress})`,
                    textColor: "#f88",
                    trailColor: "#d6d6d6",
                    backgroundColor: circleColor,
                  })}
                  value={Math.floor(progress)}
                  text={`${Math.floor(progress)}%`}
                />
              </div>
              {progress >= 0 && progress <= 2 && showBar && (
                <div style={{ marginLeft: "20px" }}>
                  <p>Cerebrum has started processing</p>
                  <p>
                    Please allow upto 10 minutes for the program to complete
                  </p>
                </div>
              )}

              {progress > 2 && progress <= 20 && showBar && (
                <div style={{ marginLeft: "20px" }}>
                  <p>Cerebrum is running through the data.</p>
                  <p>
                    Please allow upto 10 minutes for the program to complete
                  </p>
                </div>
              )}
              {progress > 20 && progress < 100 && showBar && (
                <div style={{ marginLeft: "20px" }}>
                  <p>Cerebrum is almost complete.</p>
                  <p>
                    Please allow upto 10 minutes for the program to complete
                  </p>
                </div>
              )}

              {completed == "1" && (
                <div style={{ marginLeft: "20px" }}>
                  <p>Completed</p>
                  <br />
                  <Button
                    className="w-100 py-3 primaryButton font-weight-bold mb-4"
                    onClick={handleBackToLink}
                  >
                    View Dashboard
                  </Button>
                  <Button
                    className="w-100 py-3 primaryButton font-weight-bold mb-4"
                    onClick={handledownloadFileClick}
                  >
                    {auditDownloadFileStatus}
                  </Button>
                  <Button
                    className="w-100 py-3 primaryButton font-weight-bold mb-4"
                    onClick={handleBack}
                  >
                    <img
                      src={backArrow}
                      className="me-2"
                      alt="play"
                      width="28rem"
                      height="28rem"
                    />{" "}
                    Start Again
                  </Button>
                </div>
              )}

              {completed === "-1" && (
                <>
                  <p className="mx-4">Error processing the file</p>
                  <br />
                  <Button
                    className="w-50  primaryButton font-weight-bold mb-4"
                    onClick={handleBack}
                  >
                    <img
                      src={backArrow}
                      className="me-2"
                      alt="play"
                      width="28rem"
                      height="28rem"
                    />{" "}
                    Start Again
                  </Button>
                </>
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return renderSection(type);
};

export default TabSection;
