import React, { createContext, useContext, useState } from "react";
import { initilaDetailsObj } from "../projects/web-workflow/utils/Constants";

export const ChatAI = createContext();

const ContextWrapper = ({ children }) => {
  const [conversations, setConversations] = useState([]);
  const [activeConversation, setActiveConversation] = useState({
    conversation_id: null,
    conversation_name: "",
  });
  const [selectedModal, setSelectedModal] = useState("VERSION_1");
  const [isFirstMesssge, setIsFirstMessage] = useState(true);
  const [archivedChats, setArchivedChats] = useState([]);
  const [isMessagesLoading, setIsMessagesLoading] = useState(false);

  const [messages, setMessages] = useState([]);
  const [mode, setMode] = useState("DARK");
  const [selectedCompanion, setSelectedCompanionState] = useState(
    "Discovery Companion"
  );
  const [auditDetails, setAuditDetails] = useState(initilaDetailsObj);
  const [isConversationsLoading, setIsConversationsLoading] = useState(false);
  const [hasMoreConversations, setHasMoreConversations] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const value = {
    common: {
      mode,
      setMode,
      selectedCompanion,
      setSelectedCompanionState,
    },
    chatAI: {
      conversations,
      setConversations,
      activeConversation,
      setActiveConversation,
      messages,
      setMessages,
      selectedModal,
      setSelectedModal,
      isFirstMesssge,
      setIsFirstMessage,
      archivedChats,
      setArchivedChats,
      isMessagesLoading,
      setIsMessagesLoading,
    },
    webWorkFlow: {
      auditDetails,
      setAuditDetails,
    },
    scroll: {
      isConversationsLoading,
      setIsConversationsLoading,
      currentPage,
      setCurrentPage,
      hasMoreConversations,
      setHasMoreConversations,
    },
  };
  return <ChatAI.Provider value={value}>{children}</ChatAI.Provider>;
};

export const useChatContext = () => useContext(ChatAI);

export default ContextWrapper;
