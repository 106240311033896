import React from 'react'; 
import "./LandingCards.css";
import { useNavigate } from 'react-router-dom';
import { landingPageData } from './Config';
import { useChatContext } from '../../../context';

const LandingCards = () => {
    const navigate = useNavigate();
    const {common:{setSelectedCompanionState}} = useChatContext();

    const handleCardClick = (data) => {
      const {route,title} = data;
      setSelectedCompanionState(title)
      navigate(route);
    };
    const handleDemoClick = (demoLink) => {
      window.open(demoLink, '_blank');
    };
  return (
    <div className='landingpage-wrapper'>
      {landingPageData.map((data, index) => (
        <div className={`main-landing-page${data.id}`} key={index}  onClick={() => handleCardClick(data)}>
          <div className='left-pagecoder'>
            <h2>{data.title}</h2>
            <p>{data.description}</p>
            <img src={data.image} alt={`logo${data.id}`} />
          </div>
          <div className='right-pagecoder'>
            <p>{data.rightDescription}</p>
            <ul>
              {data.features.map((feature, idx) => (
                <li key={idx}><a>{feature}</a></li>
              ))}
            </ul>
            <div className='internal-page'>
              <button>
                <img src={data.button1.icon} alt='tick' /> {data.button1.text}
              </button>
              <button className='button-demo' onClick={(e) => { 
                e.stopPropagation(); 
                handleDemoClick(data.demoLink); 
              }}>
                <img src={data.button2.icon} alt='tack' /> {data.button2.text}
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default LandingCards;
